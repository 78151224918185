import React from 'react'
import styled from 'styled-components'
import Icon from '../components/icon'
import { Link } from 'gatsby'

export default function Header() {
  return (
    <Container>
      <Link to="/">
        <Logo name="largeLogo" />
      </Link>
    </Container>
  )
}

const Container = styled.header`
  height: 80px;
  background: #4d4b84;
  padding: 0 32px;
  flex-direction: row;
  align-items: center;
`

const Logo = styled(Icon)`
  height: 40px;
`
