import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Image, { FluidObject } from 'gatsby-image'
import { LawyerProps } from './types'

interface Props extends LawyerProps {
  portrait: FluidObject
}

const MAX_TITLE_HEIGHT = 97

export default function BusinessCard({ lawyer, portrait }: Props) {
  const [showMoreBtn, setShowMore] = useState(false)
  const [showingMore, setShowingMore] = useState(false)
  const title = useRef(null)

  useEffect(() => {
    const titleDom = title.current
    if (titleDom && titleDom.offsetHeight >= MAX_TITLE_HEIGHT) {
      setShowMore(true)
    }
  }, [])

  const titleSize = {
    maxHeight: showingMore ? 'unset' : MAX_TITLE_HEIGHT,
    paddingBottom: showingMore ? 20 : 0,
  }

  return (
    <Container>
      <Portrait fluid={portrait}/>
      <Info>
        <Name>{lawyer.name}</Name>
        <Title style={titleSize} ref={title}>
          {lawyer.specialty}
          {(
            showMoreBtn &&
            <More onClick={() => setShowingMore(!showingMore)}>
              {showingMore ? 'fold' : '... more'}
            </More>
          )}
        </Title>
      </Info>
    </Container>
  )
}

const Container = styled.div`
  background: white;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(119, 127, 134, 0.3);
  height: 500px;
`

const Portrait = styled(Image)`
  flex: 1;
  width: 100%;
`

const Info = styled.div`
  flex-direction: column;
  padding: 20px;
`

const Name = styled.h2`
  font-size: 24px;
  line-height: 30px;
  color: #262b2e;
  text-transform: uppercase;
`

const Title = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #777f86;
  margin-top: 10px;
  word-break: break-all; 
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`

const More = styled.a`
  position: absolute;
  color: #ccc;
  bottom: 0;
  right: 0;
  cursor: pointer;
  display: block;
  padding-left: 50px;
  background: linear-gradient(to right, transparent, white, white);
`
