import React from 'react'
import styled from 'styled-components'
import Icon from '../components/icon'
import Website from './/website'
import { LawyerProps } from './types'
import { isPresent } from '../utils'

export default function LawfirmCard({ lawyer }: LawyerProps) {
  const { email, lawfirm } = lawyer

  const Contact = styled.div`
    margin-top: ${isPresent(lawfirm.name) ? 32 : 0}px;
    font-size: 14px;
    line-height: 20px;
    font-family: Arial, Helvetica, sans-serif;
  `

  return (
    <Container>
      {isPresent(lawfirm.name) && (<Name>{lawfirm.name}</Name>)}
      {isPresent(lawfirm.brief) && (
        <Block title="Brief">
          {lawfirm.brief.map((v, i) => (
            <p key={i}>{v}</p>
          ))}
        </Block>
      )}
      <Contact>
        {isPresent(lawfirm.website) && (<ContactItem>
          <StyledIcon name="website"/>
          <Website>{lawfirm.website}</Website>
        </ContactItem>)}
        {lawfirm.address.length ? (<ContactItem>
          <StyledIcon name="location"/>
          <Address>
            {lawfirm.address.map((v, i) =>
              isPresent(v) ? (<span key={i}>{v}</span>) : <br key={i}/>,
            )}
          </Address>
        </ContactItem>) : ''}
        {lawfirm.phone.length ? (<ContactItem>
          <StyledIcon name="phone"/>
          {lawfirm.phone.map((v, i) => (
            isPresent(v) && <span key={i}>{v}</span>
          ))}
        </ContactItem>) : ''}
        {isPresent(email) && (<ContactItem>
          <StyledIcon name="email"/>
          <a href={`mailto:${email}`}>{email}</a>
        </ContactItem>)}
      </Contact>
    </Container>
  )
}

const Container = styled.div`
  padding: 16px 20px;
  background: white;
  margin-top: 24px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
`

const Name = styled.h3``

const Block = styled.div`
  font-size: 12px;
  line-height: 20px;
  margin-top: 16px;
  font-family: Arial, Helvetica, sans-serif;

  > p:first-of-type::before {
    content: '${(props) => props.title}: ';
    font-weight: bold;
  }

  > p {
    min-height: 20px;
  }
`

const ContactItem = styled.div`
  &:not(:nth-child(1)){
    margin-top: 12px;
  }
  flex-direction: row;
  align-items: flex-start;
`

const StyledIcon = styled(Icon)`
  margin-right: 24px;
  width: 22px;
  height: 22px;
`

const Address = styled.div``
