import React from 'react'
import styled from 'styled-components'

export default function Footer() {
  return (
    <Container>
      <Links>
        <span>help</span>
        <span>contact</span>
        <span>term and conditions</span>
      </Links>
      <Copyright>Copyright © 2020 AILaw Inc. All rights reserved</Copyright>
    </Container>
  )
}

const Container = styled.footer`
  background: #262b2e;
  font-family: PingFangSC, Arial, Helvetica, sans-serif;
  color: #777f86;
  justify-content: center;
  align-items: center;
`

const Links = styled.div`
  text-transform: uppercase;
  flex-direction: row;
  font-size: 14px;
  line-height: 22px;
  margin-top: 40px;

  > span + span {
    margin-left: 30px;
  }
`

const Copyright = styled.p`
  font-size: 12px;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 70px;
`
