import React from 'react'
import styled from 'styled-components'
import Icon from '../components/icon'

interface Props {
  value: boolean
  onChange(value: boolean): void
}

export default function ShowMore({ value, onChange }: Props) {
  const handleClick = () => onChange(!value)

  return (
    <Container onClick={handleClick}>
      <span>{value ? 'less' : 'more'}</span>
      <Icon name="arrow" />
    </Container>
  )
}

const Container = styled.button`
  display: flex;
  box-sizing: border-box;
  padding: 0 12px;
  background: #717cd8;
  border-radius: 20px;
  font-size: 12px;
  line-height: 20px;
  color: #f8f8f8;
  text-transform: uppercase;
  width: 80px;
  align-items: center;
  justify-content: space-between;
`
