import React from 'react'
import styled from 'styled-components'

interface Props {
  children: string
}

export default function Website({ children }: Props) {
  const href = children.includes('http') ? children : `http://${children}`
  return (
    <Container href={href} target="_blank">
      {href}
    </Container>
  )
}

const Container = styled.a``
