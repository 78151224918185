import React from 'react'
import styled from 'styled-components'
import Icon from '../components/icon'
import Datetime from './datetime'

interface Props {
  date: string
}

export default function ExtraBar({ date }: Props) {
  return (
    <Container>
      <Leading>
        <Datetime>{date}</Datetime>
      </Leading>
      <Actions>
        <Action name="thumbsUp" />
        <Action name="share" />
      </Actions>
    </Container>
  )
}

const Container = styled.div`
  flex-direction: row;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10px;
`

const Leading = styled.div`
  flex-direction: row;
`

const Tag = styled.div`
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #717cd8;
  flex-direction: row;
  margin-left: 24px;
`

const TagIcon = styled(Icon)`
  margin-right: 10px;
`

const Actions = styled.div`
  flex-direction: row;
`

const Action = styled(Icon)`
  & + & {
    margin-left: 24px;
  }
`
