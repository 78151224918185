import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import ShowMore from './show-more'

interface Props {
  title: string
  visibleLength: number
  children: ReactNode[]
}

export default function List({
  title,
  visibleLength,
  children,
  ...props
}: Props) {
  const [shouldShowMore, setShowMore] = useState(false)

  const visibleChildren = shouldShowMore
    ? children
    : children.slice(0, visibleLength)

  return (
    <Container {...props}>
      <Title>{title}</Title>
      <Content>{visibleChildren}</Content>
      <Extra>
        {children.length > visibleLength && (
          <ShowMore value={shouldShowMore} onChange={setShowMore} />
        )}
      </Extra>
    </Container>
  )
}

const Container = styled.div``

const Title = styled.h3`
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  padding-bottom: 8px;
  border-bottom: 1px solid #979797;
  color: #262b2e;
`

const Content = styled.div`
  padding: 16px 0;
`

const Extra = styled.div`
  flex-direction: row;
  justify-content: center;
`
