import React from 'react'
import styled from 'styled-components'
import Header from './header'
import BusinessCard from './business-card'
import { PageProps } from './types'
import Introduction from './introduction'
import Videos from './videos'
import Articles from './articles'
import LawfirmCard from './lawfirm-card'
import Footer from './footer'

export default function Mobile({ lawyer, portrait }: PageProps) {
  return (
    <>
      <Header />
      <Main>
        <BusinessCard lawyer={lawyer} portrait={portrait} />
        <Introduction brief={lawyer.brief} />
        <Videos source={lawyer.videos} />
        <Articles source={lawyer.articles} />
        <LawfirmCard lawyer={lawyer} />
      </Main>
      <Footer />
    </>
  )
}

const Main = styled.main`
  padding: 24px;

  & > * + * {
    margin-top: 24px;
  }
`
