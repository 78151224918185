import React, { useState, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import ShowMore from './show-more'

interface Props {
  brief: string[]
}

export default function Introduction({ brief, ...props }: Props) {
  const [shouldShowMore, setShowMore] = useState(true)
  const [isOverflow, setOverflow] = useState(false)
  const container$ = useRef<HTMLDivElement>()

  useLayoutEffect(() => {
    const { height } = container$.current.getBoundingClientRect()
    setOverflow(height > 500)
    setShowMore(false)
  }, [])

  return (
    <Container {...props} shouldShowMore={shouldShowMore} ref={container$}>
      <Title>INTRODUCTION</Title>
      <Content>
        {brief.map((v, i) => (v == null ? <br key={i} /> : <p key={i}>{v}</p>))}
      </Content>
      {isOverflow && (
        <Extra>
          <ShowMore value={shouldShowMore} onChange={setShowMore} />
        </Extra>
      )}
    </Container>
  )
}

const Container = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 24px;
  background: white;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(119, 127, 134, 0.3);

  ${(props: { shouldShowMore: boolean }) =>
    !props.shouldShowMore && 'height: 500px;'}
`

const Title = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #262b2e;
`

const Content = styled.div`
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #777f86;
  overflow: hidden;
  flex: 1;

  > br {
    height: 20px;
  }
`

const Extra = styled.div`
  height: 54px;
  justify-content: center;
  align-items: center;
`
