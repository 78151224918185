import React from 'react'
import { VideoProps } from './types'
import styled from 'styled-components'
import ExtraBar from '../extra-bar'
import { VideoContainer, Detail, Title, Brief, Body } from './components'

export default function TabletItem({ source }: VideoProps) {
  return (
    <Container>
      <VideoContainer dangerouslySetInnerHTML={{ __html: source.source }} />
      <Detail>
        <Title>{source.title}</Title>
        <Body>
          <Brief>{source.description}</Brief>
        </Body>
        <ExtraBar date={source.date} />
      </Detail>
    </Container>
  )
}

const Container = styled.div`
  flex-direction: row;

  & + & {
    margin-top: 16px;
  }

  ${Detail} {
    margin-left: 20px;
  }
`
