import React from 'react'
import Responsible from '../components/responsible'
import Mobile from './mobile'
import Tablet from './tablet'
import { PageProps } from './types'
import GlobalStyle from '../components/global-style'
import SEO from '../components/seo'

interface Props {
  pageContext: PageProps
}

export default function ProfilePage({ pageContext }: Props) {
  return (
    <>
      <GlobalStyle />
      <SEO title={pageContext.lawyer.name} />
      <Responsible>
        <Mobile {...pageContext} />
        <Tablet {...pageContext} />
      </Responsible>
    </>
  )
}
