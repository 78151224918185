import styled from 'styled-components'

export const VideoContainer = styled.div`
  width: 180px;
  height: 108px;
`

export const Detail = styled.div`
  flex: 1;
  justify-content: space-between;
`

export const Title = styled.h3`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
  color: #262b2e;
`

export const Body = styled.div`
  flex-direction: row;
  justify-content: space-between;
`

export const Brief = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 20px;
  color: #777f86;
  flex: 1;
`
