import React from 'react'
import styled from 'styled-components'

interface Props {
  children: string
}

export default function Datetime({ children }: Props) {
  return <Container>{children}</Container>
}

const Container = styled.span`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 20px;
  color: #777f86;
`
