import React from 'react'
import styled from 'styled-components'
import ExtraBar from './extra-bar'
import List from './list'
import { Article } from './types'

interface Props {
  source: Article[]
}

export default function Articles({ source, ...props }: Props) {
  if (source == null) return null

  return (
    <List {...props} title="articles" visibleLength={5}>
      {source.map(renderItem)}
    </List>
  )
}

function renderItem(article: Article, index: number) {
  return (
    <ItemContainer key={index} href={article.link} target="_blank">
      <Title>{article.title}</Title>
      <Detail>
        <Abstract>{article.abstract}</Abstract>
      </Detail>
      <ExtraBar date={article.date} />
    </ItemContainer>
  )
}

const ItemContainer = styled.a`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 18px;
  }
`

const Title = styled.h3`
  font-size: 20px;
  line-height: 28px;
  color: #262b2e;
`

const Detail = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 20px;
  padding: 8px 0;
  color: #777f86;
  flex-direction: row;
`

const Abstract = styled.p`
  flex: 1;
  text-overflow: ellipsis;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
`
