import React from 'react'
import Responsible from '../../components/responsible'
import MobileVideos from './mobile'
import TabletVideos from './tablet'
import List from '../list'
import { Video } from '../types'

interface Props {
  source: Video[]
}

export default function Videos({ source }: Props) {
  if (source == null) return null

  return (
    <Responsible>
      <List title="videos" visibleLength={4}>
        {source.map((video) => (
          <MobileVideos key={video.source} source={video} />
        ))}
      </List>
      <List title="videos" visibleLength={4}>
        {source.map((video) => (
          <TabletVideos key={video.source} source={video} />
        ))}
      </List>
    </Responsible>
  )
}
