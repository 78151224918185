import React from 'react'
import Header from './header'
import Footer from './footer'
import styled from 'styled-components'
import BusinessCard from './business-card'
import LawfirmCard from './lawfirm-card'
import Videos from './videos'
import Articles from './articles'
import Introduction from './introduction'
import { PageProps } from './types'

export default function ProfilePage({ lawyer, portrait }: PageProps) {
  return (
    <>
      <Header />
      <Main>
        <Sidebar>
          <BusinessCard lawyer={lawyer} portrait={portrait} />
          <LawfirmCard lawyer={lawyer} />
        </Sidebar>
        <Content>
          <Introduction brief={lawyer.brief} />
          <Videos source={lawyer.videos} />
          <Articles source={lawyer.articles} />
        </Content>
      </Main>
      <Footer />
    </>
  )
}

const Main = styled.main`
  padding: 38px 32px;
  flex-direction: row;
`

const Sidebar = styled.div`
  flex-direction: column;
  width: 320px;
`

const Content = styled.div`
  flex: 1;
  margin-left: 24px;
  overflow: hidden;

  & > * + * {
    margin-top: 24px;
  }
`
