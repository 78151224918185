import React, { ReactChild } from 'react'
import styled from 'styled-components'

type ChildrenTuple = [ReactChild, ReactChild]

interface Props {
  children: ChildrenTuple
}

export default function Responsible({ children }: Props) {
  return (
    <Container>
      <div className="mobile">{children[0]}</div>
      <div className="tablet">{children[1]}</div>
    </Container>
  )
}

const Container = styled.div`
  > .mobile {
    display: none;
  }

  @media (max-width: 768px) {
    > .mobile {
      display: flex;
    }

    > .tablet {
      display: none;
    }
  }
`
